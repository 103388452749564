export default class StartDatePicker {
  constructor(input_selector) {
    console.log("StartDatePicker::constructor");

    this.date_field = $(input_selector);
    this.init(input_selector);
  }

  init(input_selector) {
    $(input_selector).datepicker({
      language: "uk",
      autoclose: true,
      disableTouchKeyboard: true,
      todayHighlight: true,
      // startDate: this.date_field[0].dataset.startDate,
      endDate: this.date_field[0].dataset.endDate,
    });
  }
}

export default class Nav {
  constructor(button, shevron, up, down) {
    this.button = document.querySelectorAll(button);
    this.shevron = document.querySelectorAll(shevron);
    this.up = up;
    this.down = down;

    this.init();
  }

  init() {
    const button = this.button;
    const shevron = this.shevron;
    const up = this.up;
    const down = this.down;

    $(this.button).on('click', function(e) {
      this.shevron = $(e.target).closest(button).find(shevron);
      if ($(this.shevron).hasClass(up)) {
        $(this.shevron).removeClass(up);
        $(this.shevron).addClass(down);
      } else {
        $(this.shevron).removeClass(down);
        $(this.shevron).addClass(up);
      }
      toggleScroll();
    })

    const businessContent = $('.nav-businesses-content');
    businessContent.on('show.bs.collapse', '.collapse', function() {
      hideOtherCollapses(businessContent);
    });

    const eventsContent = $('.nav-events-content');
    eventsContent.on('show.bs.collapse', '.collapse', function() {
      hideOtherCollapses(eventsContent);
    });

    function hideOtherCollapses(content) {
      content.find('.collapse.show').prev().find(".fas").removeClass(up);
      content.find('.collapse.show').prev().find(".fas").addClass(down);
      content.find('.collapse.show').collapse('hide');
    }

    function openNav() {
      $('.nav').addClass('nav--shown');
      $('.nav-fade').addClass('nav-fade--transition');
      $('.nav-fade').css('z-index', 1);
      $('.nav-fade').addClass('nav-fade--shown');
      $('.nav-open-button .fas').removeClass('fa-bars');
      $('.nav-open-button .fas').addClass('fa-times');
      $('body').css('overflow', 'hidden');
    }

    function closeNav() {
      $('.nav').removeClass('nav--shown');
      $('.nav-fade').removeClass('nav-fade--shown');
      $('.nav-open-button .fas').removeClass('fa-times');
      $('.nav-open-button .fas').addClass('fa-bars');
      $('body').css('overflow', 'auto');
      setTimeout(function() {
        $('.nav-fade').css('z-index', -99);
      }, 500)
    }

    $(document).on('click', '.nav-open-button', function() {
      if ($('.nav').hasClass('nav--shown')) {
        closeNav();
      } else {
        openNav();
      }
    })

    $(document).on('click', '.nav-fade', function() {
      closeNav();
    })

    toggleScroll();

    function toggleScroll() {
      setTimeout(function() {
        if ($('.nav-container .main').outerHeight() + $('.nav-container .bottom').outerHeight() >  $('.nav').outerHeight() - $('.nav .top').outerHeight()) {
          $('.nav-container').css('overflow', 'scroll');
        } else {
          $('.nav-container').css('overflow', 'hidden');
        }
      }, 400)
    }
  }
}
export default class BusinessJobs {
  constructor() {
    console.log("BusinessJobs::constructor");
    this.init();
  }
  init() {
    const editorToolbar = document.getElementById('trix-toolbar-1')
    if (editorToolbar) {
      editorToolbar.querySelector('.trix-button--icon-strike').style.display = 'none'
      editorToolbar.querySelector('.trix-button--icon-heading-1').style.display = 'none'
      editorToolbar.querySelector('.trix-button--icon-quote').style.display = 'none'
      editorToolbar.querySelector('.trix-button--icon-code').style.display = 'none'
      editorToolbar.querySelector('.trix-button-group--file-tools').style.display = 'none'
    }

    const currentURL = window.location.pathname.split('/').pop()
    const activeBtn = document.getElementById('activeBtn')
    const inactiveBtn = document.getElementById('inactiveBtn')
    if (activeBtn && inactiveBtn) {
      if (currentURL === 'active') {
        activeBtn.classList.add('active')
      } else {
        activeBtn.classList.remove('active')
        inactiveBtn.classList.add('active')
      }
    }
  }
}

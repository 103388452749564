export default class PhotoUploader {
    constructor(area_selector = "#drag-drop-area") {
        this.area_selector = area_selector;
        this.uploader_dataset = this.get_uploader_dataset();
        this.uploader_acceess_token = this.uploader_dataset.accessToken;
        this.init();
        this.init_remove_buttons();
        this.init_rotate_buttons();
    }

    area() {
        return document.querySelector(this.area_selector);
    }

    get_uploader_dataset() {
        return this.area().dataset;
    }

    init() {
        const Uppy = require("@uppy/core");
        const uk_UA = require("../uk_UA");
        const Dashboard = require("@uppy/dashboard");
        const XHRUpload = require("@uppy/xhr-upload");
        const ImageEditor = require("@uppy/image-editor");
        const TextRestrictions =
            "Максимальні розміри для завантаженя: 5000x5000 пікселів, 15 мб. Доступні формати для завантаженя: JPG, PNG";

        Object.keys(window).forEach((key) => {
            if (/^on/.test(key)) {
                window.addEventListener(key.slice(2), (event) => {
                    const insertWrap = document.querySelector(
                        ".uppy-Dashboard-AddFiles-info"
                    );
                    if (insertWrap !== null && insertWrap.childElementCount === 0) {
                        const innerTag = document.createElement("div");
                        innerTag.classList.add("uppy-Dashboard-note");
                        innerTag.innerHTML =
                            "Максимальні розміри для завантаженя: <strong>5000x5000 пікселів, 15 мб</strong> <br> Доступні формати для завантаженя: <strong>JPG, PNG</strong>";
                        insertWrap.appendChild(innerTag);
                    }
                });
            }
        });

        document
            .querySelector("#sortable")
            .addEventListener("touchstart", function(event) {
                let eventDragenter = new Event("dragenter");
                let eventDragover = new Event("dragover");
                let eventPointerdown = new Event("pointerdown");
                let element = document.querySelector("#sortable");
                element.dispatchEvent(eventDragenter);
                element.dispatchEvent(eventDragover);
                element.dispatchEvent(eventPointerdown);
                if (event.target.classList.contains("UppyModalOpenerBtn")) {
                    uppy.getPlugin("Dashboard").openModal();
                }
            });

        const uppy = Uppy({
            debug: false,
            locale: uk_UA,
            autoProceed: false,
            meta: {
                business_id: this.uploader_dataset.businessId,
            },
            restrictions: {
                maxFileSize: 1024 * 1024 * 15,
                maxNumberOfFiles: 1000,
                minNumberOfFiles: 1,
                allowedFileTypes: [".jpg", ".jpeg", ".jfif", ".jpe", ".png"],
            },
        });

        uppy.use(Dashboard, {
            browserBackButtonClose: true,
            closeModalOnClickOutside: true,
            disablePageScrollWhenModalOpen: false,
            inline: false,
            proudlyDisplayPoweredByUppy: false,
            replaceTargetContent: true,
            showProgressDetails: true,
            target: this.area_selector,
            trigger: ".UppyModalOpenerBtn",
            height: 470,
            // note: TextRestrictions,
        });
        uppy.use(XHRUpload, {
            headers: {
                authorization: "Bearer " + this.uploader_acceess_token,
            },
            endpoint: this.uploader_dataset.action,
            bundle: false,
            fieldName: "file",
            limit: 1,
            resume: true,
            removeFingerprintOnSuccess: true,
            autoRetry: true,
            retryDelays: [0, 1000, 3000, 5000],
        });

        uppy.use(ImageEditor, {
            target: Dashboard,
            id: "ImageEditor",
            quality: 1.0,
            cropperOptions: {
                viewMode: 1,
                background: false,
                autoCropArea: 1,
                responsive: true,
            },
            actions: {
                revert: true,
                rotate: true,
                flip: true,
                zoomIn: true,
                zoomOut: true,
                cropSquare: true,
                cropWidescreen: true,
                cropWidescreenVertical: true,
            },
        });

        uppy.on("upload-error", (file, error, response) => {
            uppy.info(file.name + ": " + response.body.message, "error", 3000);
        });

        uppy.on("complete", (result) => {
            if (result.successful.length > 0) {
                uppy.setState({
                    should_reload: true,
                });
            }
        });

        uppy.on("file-added", (file) => {
            const fileReader = new FileReader();

            fileReader.readAsDataURL(file.data);

            fileReader.onload = () => {
                const image = new Image();

                image.onload = () => {
                    if (image.width > 5000 && image.height > 5000) {
                        uppy.info("Розмір файлу перевищує обмеження в 5000х5000 px");

                        uppy.removeFile(file.id);
                    }

                    if (image.width > 5000) {
                        uppy.info("Розмір файлу перевищує обмеження в 5000х5000 px");

                        uppy.removeFile(file.id);
                    }

                    if (image.height > 5000) {
                        uppy.info("Розмір файлу перевищує обмеження в 5000х5000 px");

                        uppy.removeFile(file.id);
                    }
                };

                image.src = fileReader.result;
            };
        });

        uppy.on("dashboard:modal-closed", () => {
            uppy.setState({
                should_reload: true,
            });
            location.reload();
        });
    }

    init_remove_buttons() {
        const buttons = document.getElementsByClassName("confirm-remove");

        Array.from(buttons).forEach((button) => {
            button.addEventListener("click", (e) => {
                const element = e.target;

                e.preventDefault();
                element.closest(".photo-container").remove();

                PhotoUploader.remove_photo_request(
                    element.dataset.action,
                    element.dataset.accessToken,
                    (data) => location.reload(),
                    (e) => location.reload()
                );
            });
        });
    }

    init_rotate_buttons() {
        let left_buttons = document.querySelectorAll(
            ".photo-container .rotation-container a.rotation-left"
        );
        let right_buttons = document.querySelectorAll(
            ".photo-container .rotation-container a.rotation-right"
        );

        left_buttons.forEach(function(button) {
            button.addEventListener("click", function(e) {
                e.preventDefault();
                let photoWrapper = $(this).closest(".photo-wrapper");
                if (photoWrapper.hasClass("isDisabled")) {
                    return;
                }

                let photo = photoWrapper.find("a.photo-link:first");
                let left = photoWrapper.find(".rotation-container a.rotation-left");
                let right = photoWrapper.find(".rotation-container a.rotation-right");
                let deleteBtn = photoWrapper.find("a.remove");
                const loader = photoWrapper.find(".gallery-loader");
                photo.addClass("isDisabled");
                left.addClass("isDisabled");
                right.addClass("isDisabled");
                deleteBtn.addClass("isDisabled");
                photo.removeClass("effectable");
                loader.removeClass("hidden");

                PhotoUploader.call_rotate_image_request(
                    this.dataset.action,
                    this.dataset.accessToken, -90,
                    photoWrapper
                );
            });
        });

        right_buttons.forEach(function(button) {
            button.addEventListener("click", function(e) {
                e.preventDefault();
                let photoWrapper = $(this).closest(".photo-wrapper");
                if (photoWrapper.hasClass("isDisabled")) {
                    return;
                }

                let photo = photoWrapper.find("a.photo-link:first");
                let left = photoWrapper.find(".rotation-container a.rotation-left");
                let right = photoWrapper.find(".rotation-container a.rotation-right");
                let deleteBtn = photoWrapper.find("a.remove");
                const loader = photoWrapper.find(".gallery-loader");
                photo.addClass("isDisabled");
                left.addClass("isDisabled");
                right.addClass("isDisabled");
                deleteBtn.addClass("isDisabled");
                photo.removeClass("effectable");
                loader.removeClass("hidden");

                PhotoUploader.call_rotate_image_request(
                    this.dataset.action,
                    this.dataset.accessToken,
                    90,
                    photoWrapper
                );
            });
        });
    }

    static call_rotate_image_request(action, token, angle, photoWrapper) {
        let currentTime = new Date().getTime();
        let photo = photoWrapper.find("a.photo-link:first");
        let left = photoWrapper.find(".rotation-container a.rotation-left");
        let right = photoWrapper.find(".rotation-container a.rotation-right");
        let deleteBtn = photoWrapper.find("a.remove");
        const loader = photoWrapper.find(".gallery-loader");
        PhotoUploader.rotate_photo_request(
            action,
            token,
            angle,
            function(data) {
                let changedLink = data.big.substr(0, 39) + data.big.substr(39)
                photoWrapper.attr("data-time", currentTime);
                photo.attr("href", changedLink + "?time=" + currentTime);
                photo.data("time", currentTime);
                photo.css(
                    "background-image",
                    "url(" + changedLink + "?time=" + currentTime + ")"
                );
                photo.attr("data-remote", changedLink + "?time=" + currentTime);
                photo.addClass("effectable");
                left.removeClass("isDisabled");
                right.removeClass("isDisabled");
                deleteBtn.removeClass("isDisabled");
                photo.removeClass("isDisabled");
                loader.addClass("hidden");
            },
            function(e) {
                console.log(e);
                left.removeClass("isDisabled");
                right.removeClass("isDisabled");
                deleteBtn.removeClass("isDisabled");
                photo.removeClass("isDisabled");
                loader.addClass("hidden");
            }
        );
    }

    static remove_photo_request(action, token, success_func, error_func) {
        $.ajax({
            type: "DELETE",
            headers: {
                Authorization: "Bearer " + token,
            },
            url: action,
            timeout: 600000,
            success: success_func,
            error: error_func,
        });
    }

    static rotate_photo_request(action, token, angle, success_func, error_func) {
        $.ajax({
            type: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
            url: action,
            data: {
                angle: angle,
            },
            timeout: 600000,
            success: success_func,
            error: error_func,
        });
    }
}

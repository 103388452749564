export default class MobileNav {
  constructor(buttonId, navId, openedClass) {
    this.button = document.getElementById(buttonId);
    this.nav = document.getElementById(navId);
    this.openedClass = openedClass;

    this.init();
  }

  init() {
    const nav = this.nav;
    const openedClass = this.openedClass;
    $(this.button).on('click', function() {
      $(nav).toggleClass(openedClass);
    })
  }
}
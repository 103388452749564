export default class EndDatePicker {
  constructor(input_selector) {
    console.log("EndDatePicker::constructor");

    this.date_field = $(input_selector);
    this.init(input_selector);
  }

  init(input_selector) {
    $(input_selector).datepicker({
      language: "uk",
      autoclose: true,
      disableTouchKeyboard: true,
      todayHighlight: true,
    });
  }
}

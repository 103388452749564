export default class Menu {
  constructor(iconId, menuId, shownClass) {
    this.icon = document.getElementById(iconId);
    this.menu = document.getElementById(menuId);
    this.shownClass = shownClass;

    this.init();
  }

  init() {
    // this.icon.addEventListener('click', () => {
    //   this.menu.classList.toggle(this.shownClass);
    // })
  }
}
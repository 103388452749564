$("#send_code").on("click", () => {
  display = document.querySelector("#timer");
  let oneMinute = 60;
  startTimer(oneMinute, display);
  sendFun();
});

$("#check_code").on("click", () => {
  checkFun();
});

$("#update_data").on("click", () => {
  updateFun();
});

$(document).ready(function () {
  $("#send_code").attr("disabled", "disabled");
  $("#trial_phone_number").on("keyup", function () {
    let empty = false;
    let num = new RegExp(
      /\+(\d{3})\s(\((\d{2})\))\s(\d{3})\-(\d{2})\-(\d{2})$/
    );
    console.log($(this).val().length);
    $("#trial_phone_number").each(function () {
      empty = num.test($(this).val());
    });

    if (empty) $("#send_code").attr("disabled", false);
    else $("#send_code").attr("disabled", "disabled");
  });
});

$(document).ready(function () {
  $("#check_code").attr("disabled", "disabled");
  $("#code").on("keyup", function () {
    let empty = false;
    let num = new RegExp("^[0-9]{4}$");
    console.log($(this).val().length);
    $("#code").each(function () {
      empty = num.test($(this).val());
    });

    if (empty) $("#check_code").attr("disabled", false);
    else $("#check_code").attr("disabled", "disabled");
    if (empty) $("#code").removeClass("not-valid");
    else $("#code").addClass("not-valid");
  });
});

$("#trial_phone_number").focus(function () {
  var input = this;
  setTimeout(function () {
    input.setSelectionRange(6, 6);
  }, 6);
});

function sendFun() {
  var trial_phone_number = $("#trial_phone_number");
  $.ajax({
    dataType: "json",
    url: "/users/send_sms_for_phone_verification",
    type: "POST",
    data: $(trial_phone_number).serialize(),
    success: (data) => {},
    error: (data) => {
      location.reload();
      alert("Некоректний номер телефону");
    },
  });
}

function checkFun() {
  var code = $("#code");
  $.ajax({
    dataType: "json",
    url: "/users/verify_from_message",
    type: "POST",
    data: $(code).serialize(),
    success: (data) => {},
    error: (data) => {
      location.reload();
      alert("Невірний код з повідомлення");
    },
  });
}

function updateFun() {
  location.reload();
}

window.onload = function () {
  MaskedInput({
    elm: document.getElementById("trial_phone_number"), // select only by id
    format: "+380 (__) ___-__-__",
    separator: "+380 (   )-",
  });

  function checkEnter(e) {
    e = e || Event;
    var txtArea = /textarea/i.test((e.target || e.srcElement).tagName);
    return txtArea || (e.keyCode || e.which || e.charCode || 0) !== 13;
  }
  let phoneNumber = document.querySelector("#trial_phone_number");
  let code = document.querySelector("#code");
  if (phoneNumber && code) {
    phoneNumber.onkeypress = checkEnter;
    code.onkeypress = checkEnter;
  }
};

function startTimer(duration, display) {
  let timer = duration,
    minutes,
    seconds;
  let i = setInterval(function () {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    display.textContent = minutes + ":" + seconds;
    console.log(timer);

    if (--timer < 0) {
      clearInterval(i);
      $(".timer").addClass("hidden");
      $("#send_code_again").removeClass("hidden");
    }

    $("#autorizationModalSecond").on("hidden.bs.modal", function () {
      clearInterval(i);
      $("#send_code_again").addClass("hidden");
      document.getElementById("timer").innerText = "01:00";
      $(".timer").removeClass("hidden");
    });
  }, 1000);
}

$(document).on("click", "#send_code_again", function (e) {
  $(e.target).addClass("hidden");
  $(".timer").removeClass("hidden");
  display = document.querySelector("#timer");
  let oneMinute = 60;
  startTimer(oneMinute, display);
});

$("#autorizationModalConfirm").on("hidden.bs.modal", function () {
  location.reload();
});

import Chart from "chart.js";

const controller = document.querySelector("body").dataset.controller;
export default class StatsChart {
  constructor(canvas_selector) {
    let canvas = document.querySelector(canvas_selector);

    if (!canvas) return;

    this.ctx = canvas.getContext("2d");
    this.data = canvas.dataset;

    if (controller === "businesses-show") {
      if (window.innerWidth < 1921) {
        this.ctx.canvas.height = 100;
      }
    }
    if (controller === "businesses-statistics") {
      if (window.innerWidth > 1023) {
        this.ctx.canvas.height = 51;
      }
      if (window.innerWidth < 576 && window.innerHeight < 679) {
        this.ctx.canvas.height = 5;
      }
    }
    if (window.innerWidth < 576) {
      this.ctx.canvas.height = 300;
    }

    this.build();
  }

  build() {
    let statsChart = new Chart(this.ctx, {
      type: "line",
      data: {
        labels: JSON.parse(this.data.labels),
        datasets: [
          {
            label: "Перегляди",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 1)",
            data: JSON.parse(this.data.viewTimesData),
            borderWidth: 1,
          },
          {
            label: "Попадання в пошуку",
            data: JSON.parse(this.data.searchesData),
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
      },
    });
  }
}

export default class Comments {
  constructor() {
    this.time_elements = $("time.timeago");
    this.to_answer_buttons = $(".to-answer");

    this.timeage_init();
    this.answer_buttons_init();
  }

  timeage_init() {
    this.time_elements.timeago();
  }

  answer_buttons_init() {
    this.to_answer_buttons.on("click", function (e) {
      e.preventDefault();

      $(this).parent().find(".answer-block").toggle("fast");
    });
  }
}

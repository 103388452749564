import consumer from './consumer';

consumer.subscriptions.create(
    'GetMessagesCountChannel', {
        connected() {
            // Called when the subscription is ready for use on the server
            console.log("GetMessagesCountChannel connected...");
        },
        received(data) {
            const businessID = data.update_for_count
            const menuID = 'currentBusinessesCollapse_' + businessID
            const menu = document.getElementById(menuID)
            const menuItem = menu.querySelector('span.feedback-counter-chat')
            const currentBusinessId = parseInt(location.pathname.split("/")[2])
            const currentChat = document.getElementById('chatField')

            //count in side menu
            if (currentChat !== null && currentChat.dataset.chatId != data.chat_id ||
              document.getElementById('businesses-show') ) {
                if (menuItem !== null) {
                    let msgCount = parseInt(menuItem.textContent)
                    menuItem.textContent = msgCount + 1
                    menuItem.style.display = 'inline-block'
                } else {
                    const chatLink = menu.querySelector('a.chat-link')
                    let counterElement = document.createElement('span')
                    counterElement.classList.add('feedback-counter', 'feedback-counter-chat')
                    counterElement.textContent = 1
                    chatLink.appendChild(counterElement)
                }


                // count in top menu

                if (businessID === currentBusinessId) {
                    const topNavLink = document.querySelector('.top-nav a.chat-field')
                    const topNavItem = topNavLink.querySelector('span.user_messages-count')
                    if (topNavItem !== null) {
                        let msgCount = parseInt(topNavItem.textContent)
                        topNavItem.textContent = msgCount + 1
                        topNavItem.style.display = 'inline-block'
                    } else {
                        let counterElement = document.createElement('span')
                        counterElement.classList.add('user_messages-count')
                        counterElement.textContent = 1
                        topNavLink.appendChild(counterElement)
                    }
                }
            }

            if (document.getElementById('businesses-show') && data.update_for_count == currentBusinessId) {
                const messagesSection = document.getElementById('messagesSection')

                if (messagesSection !== null) {
                    const newMsgCount = document.getElementById('newMsgCounter')
                    const allMsgCount = document.getElementById('allMsgCounter')

                    newMsgCount.textContent = parseInt(newMsgCount.textContent) + 1
                    allMsgCount.textContent = parseInt(allMsgCount.textContent) + 1

                    const userName = messagesSection.querySelector('.messages__content--name')
                    userName.textContent = 'Анонімний'

                    let todayDate = new Date().toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })
                    let yestardayDate = new Date(Date.now() - 864e5).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })
                    let msgDate = new Date(data.msg_time).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })

                    if (msgDate === todayDate) {
                        messagesSection.querySelector('.messages-last__content--date').textContent = `сьогодні, ${new Date(data.msg_time).toLocaleString(
                          [], {hour: '2-digit', minute:'2-digit'}
                        )}`
                    } else if (msgDate === yestardayDate) {
                        messagesSection.querySelector('.messages-last__content--date').textContent = `вчора, ${new Date(data.msg_time).toLocaleString(
                          [], {hour: '2-digit', minute:'2-digit'}
                        )}`
                    } else {
                        messagesSection.querySelector('.messages-last__content--date').textContent = `${new Date(data.msg_time).toLocaleString(
                          [], {year: 'numeric', month: 'numeric',
                              day: 'numeric',hour: '2-digit', minute:'2-digit'}
                        )}`
                    }

                    messagesSection.querySelector('.messages-last__content--text').textContent = data.msg_data
                }
            }
        },
    })

export default class Qr {
  constructor() {
    this.init();
  }

  init() {
    $(document).on('click', '.create-new-qr-table-button', function (e) {
      e.preventDefault();
      let newLine = $("#new-qr-table-form");
      newLine.toggleClass("d-none");
    });

    $(document).on('click', '.cancel-table-form', function (e) {
      e.preventDefault();
      let newLine = $(e.target).closest("#new-qr-table-form");
      newLine.addClass("d-none");
    });

    $(document).on('click', '.cancel-old-qr-table-form', function (e) {
      e.preventDefault();
      let form = $(e.target).closest(".old-qr-table-form");
      let oldLine = form.prev(".table-row.table-row--item");
      form.addClass("d-none");
      oldLine.removeClass("d-none");
    });

    $(document).on('click', '.btn-edit.edit-old-qr-table-row', function (e) {
      e.preventDefault();
      let oldLine = $(e.target).closest(".table-row.table-row--item");
      let form = oldLine.next(".old-qr-table-form");
      oldLine.addClass("d-none");
      form.removeClass("d-none");
    });
  }
}

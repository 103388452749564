export default class Feedbacks {
  constructor() {
    console.log("Feedbacks::constructor");
    this.init();
  }
  init() {
    $(document).on("click", "a.process-feedback", function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      const button = $(e.target);
      const link = button.parents(".table-row--item").find(".answer");
      button.closest(".table-row--item").toggleClass("done");

      $.ajax({
        type: "PUT",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        url: $(this).attr("href"),
        success: function (response) {
          button.closest(".feedback").toggleClass("feedback--unprocessed");
          if (button.hasClass("button-main")) {
            button.removeClass("button-main");
            button.addClass("button-secondary");
            button.text("Опрацьовано");
            link.addClass("answer-secondary");
          } else {
            button.removeClass("button-secondary");
            button.addClass("button-main");
            button.text("Опрацювати");
            link.removeClass("answer-secondary");
            link.addClass("answer");
          }
        },
      });
      return false;
    });

    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    document.addEventListener("DOMContentLoaded", answerLinkInit);
    function answerLinkInit() {
      let answerLinks = document.querySelectorAll("a.answer");
      let answerBtn = document.querySelectorAll(
        ".process-action .process-feedback div"
      );
      for (let i = 0; i < answerBtn.length; i++) {
        if (answerBtn[i].classList.contains("button-secondary")) {
          answerLinks[i].classList.add("answer-secondary");
          answerLinks[i].parentElement.classList.add("answer-secondary");
        }
      }
      for (let i = 0; i < answerLinks.length; i++) {
        answerLinks[i].addEventListener("click", function () {
          getModal(event.target);
        });
      }
    }
    function getModal(dataTarget) {
      let modalId = dataTarget.dataset.target;
      let modalList = document.querySelectorAll(".feedback-modal");
      let modalTarget;
      let missClick;
      for (let i = 0; i < modalList.length; i++) {
        let modalItem = "#" + modalList[i].id;
        if (modalItem == modalId) {
          modalTarget = modalList[i];
        }
      }
      missClick = modalTarget.querySelector(".modal-content");
      if (missClick) {
        document.addEventListener("click", function (event) {
          let isClickInside = missClick.contains(event.target);
          if (!isClickInside) {
            document.location.reload();
          }
        });
      }
    }
  }
}

export default class UserMessages {
    constructor() {
        console.log("UserMessages::constructor");
        this.init();
    }
    init() {
        setInterval(function(){
            const activeHeader = document.querySelector(".chat-row__header-link.active-header")
            if (activeHeader !== null) {
                sessionStorage.setItem('key', activeHeader.id);
            } else {
                sessionStorage.clear()
            }
            location.reload()
        }, 60000 * 120);
        document.addEventListener("DOMContentLoaded", () => {
            if (sessionStorage.getItem('key') !== null) {
                const activeHeader = document.getElementById(sessionStorage.getItem('key'))
                getMessages(sessionStorage.getItem('key'))
                  .then((data) => {
                      getChatContent(data)
                  })
                  .catch((error) => {
                      console.clear()
                  })
                showChatContent()
                sessionStorage.clear()
            }
            const chatHeaders = document.querySelectorAll(".chat-row__header-link")
            chatHeaders.forEach(item => {
                item.addEventListener("click", (event) => {
                    event.preventDefault()
                    getMessages(item.id)
                        .then((data) => {
                            getChatContent(data)
                        })
                        .catch((error) => {
                            console.clear()
                        })
                    showChatContent()
                })
            })
        })

        const observer = new MutationObserver(function(mutations_list) {
            mutations_list.forEach(function(mutation) {
                mutation.addedNodes.forEach(function(added_node) {
                    if (added_node) {
                        added_node.addEventListener("click", () => {
                                getMessages(added_node.id)
                                    .then((data) => {
                                        getChatContent(data)
                                    })
                                    .catch((error) => {
                                        console.clear()
                                    })
                                showChatContent()
                                const chatHeaders = document.querySelectorAll(".chat-row__header-link")
                                chatHeaders.forEach(item => {
                                    if (item !== added_node && item.classList.contains('active-header')) {
                                        item.classList.remove('active-header')
                                    }
                                })
                                added_node.classList.add('active-header')
                                added_node.querySelector('.chat-row__header-info').classList.remove('new-message')

                            })
                            // observer.disconnect()
                    }
                })
            })
        })

        if (document.querySelector(".chat-row")) {
            observer.observe(document.querySelector(".chat-row"), { subtree: false, childList: true });
        }

        function getMessages(id) {

            return new Promise((resolve, reject) => {
                let chatId = id.replace(/^\D+/g, '');
                let request = $.ajax({
                    dataType: "json",
                    url: location.pathname + `/${chatId}`,
                    type: "GET",
                    beforeSend: function(jqXHR) {
                        $("#chatField").empty()
                        $("#loader").fadeTo(500, 1)
                    },
                    complete: function() {
                        $("#loader").fadeOut(500)
                    },
                    success: function(data) {
                        $("#chatField").empty()
                        document.getElementById('chatField').dataset.chatId = data.chat_id
                        resolve(data)
                    },
                    error: function(error) {
                        reject(error)
                    },
                })
            })
        }

        function showChatContent() {
            document.querySelector('.showChat').classList.remove('hide')
        }

        function getChatContent(messagesList) {

            let headers = document.querySelectorAll('.chat-row__header-link')
            let id = 'field' + messagesList.chat_id

            headers.forEach(header => {
                if (header.classList.contains('active-header')) {
                    header.classList.remove('active-header')
                }
                if (header.id === id) {
                    header.classList.add('active-header')
                }
            })

            let messagesArray = messagesList.messages
            let currentUsername = messagesList.user
            const chatField = document.getElementById('chatField')
            const fixScrolling = document.createElement('div')
            fixScrolling.classList.add('fix-scrolling')
            const messageItem = document.createElement('div')
            messageItem.classList.add('user-message')
            const messageItemHeader = document.createElement('div')
            messageItemHeader.classList.add('message__header')
            let messageItemName = document.createElement('span')
            messageItemName.classList.add('message__header-name')
            let messageItemDate = document.createElement('span')
            messageItemDate.classList.add('message__header-date')
            let messageItemText = document.createElement('div')
            const wrap = document.createElement('div')
            messageItem.append(messageItemHeader, messageItemText)

            messagesArray.forEach(message => {
                const messageItem = document.createElement('div')
                const messageItemHeader = document.createElement('div')
                messageItemHeader.classList.add('message__header')
                let messageItemName = document.createElement('span')
                messageItemName.classList.add('message__header-name')
                let messageItemDate = document.createElement('span')
                messageItemDate.classList.add('message__header-date')
                let messageItemText = document.createElement('div')
                messageItemText.classList.add('message__body')
                const wrap = document.createElement('div')

                if (message.sender_id) {
                    messageItem.classList.add('company-message')
                    messageItemText.classList.add('message__body-company')
                    messageItemName.textContent = 'Ви'
                } else {
                    messageItem.classList.add('user-message')
                    messageItemText.classList.add('message__body-user')
                    if (currentUsername === null) {
                        messageItemName.textContent = 'Анонімний'
                    } else {
                        messageItemName.textContent = currentUsername.name
                    }
                }

                let todayDate = new Date().toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })
                let yestardayDate = new Date(Date.now() - 864e5).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })
                let msgDate = new Date(message.timestamp).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })

                if (msgDate === todayDate) {
                    messageItemDate.textContent = `сьогодні, ${new Date(message.timestamp).toLocaleString(
                        [], {hour: '2-digit', minute:'2-digit'}
                    )}`
                } else if (msgDate === yestardayDate) {
                    messageItemDate.textContent = `вчора, ${new Date(message.timestamp).toLocaleString(
                        [], {hour: '2-digit', minute:'2-digit'}
                    )}`
                } else {
                    messageItemDate.textContent = `${new Date(message.timestamp).toLocaleString(
                                                [], {year: 'numeric', month: 'numeric', 
                                                                    day: 'numeric',hour: '2-digit', minute:'2-digit'}
                                                    )}`
                }

                messageItemText.textContent = message.msg_data

                messageItemHeader.append(messageItemName, messageItemDate)
                messageItem.append(messageItemHeader, messageItemText)

                chatField.append(messageItem)
            })
            chatField.insertBefore(fixScrolling, chatField.firstChild)
            chatField.scrollTop = chatField.scrollHeight;

            const sendForm = document.querySelector('#messageForm')
            sendForm.querySelector('[name="chat_id"]').value = messagesList.chat_id
        }
    }
}

var uk_UA = {};
uk_UA.strings = {
  addMoreFiles: "Додайте ще фали",
  addingMoreFiles: "Додавання додаткових файлів",
  allowAccessDescription:
    "Чтобы сделать фото или видео, пожалуйста, разрешите доступ к камере для этого сайта",
  allowAccessTitle: "Пожалуйста, разрешите доступ к вашей камере",
  authenticateWithTitle:
    "Пожалуйста, авторизуйтесь в %{pluginName} чтобы выбрать файлы",
  authenticateWith: "Подключиться к %{pluginName}",
  back: "Назад",
  addMore: "Добавити ще",
  browse: "виберіть",
  cancel: "Відмінити",
  cancelUpload: "Відмінити завантаження",
  chooseFiles: "Вибрати файли",
  closeModal: "Закрити вікно",
  companionAuthError: "Потрібна авторизація",
  companionError: "Не удалось подключиться к Companion",
  // «Готово» вместо «загрузка завершена», потому что кроме загрузки бывает encoding — транскодирование файлов
  complete: "Готово",
  // «Нет подключения к интернету» — «Подключено к интернету»
  connectedToInternet: "Підключено до інтернету",
  copyLink: "Скопіювати посилання",
  copyLinkToClipboardFallback: "Скопіюйте посилання",
  copyLinkToClipboardSuccess: "Посилання скопійоване в буфер обміну",
  creatingAssembly: "Підготовка завантаження...",
  creatingAssemblyFailed: "Transloadit: Не удалось создать Assembly",
  dashboardTitle: "Завантажувач файлів",
  dashboardWindowTitle:
    "Вікно завантаження файлів (Натисніть escape, щоб закрити)",
  dataUploadedOfTotal: "%{complete} з %{total}",
  done: "Готово",
  dropHereOr: "Перетягніть або %{browse} файли",
  dropHint: "Перетягніть файли сюди",
  dropPaste: "Перетягніть, вставте або %{browse} фото",
  dropPasteImport: "Перетягніть, вставте, %{browse} або імпортуйте файли",
  edit: "Редагувати",
  editFile: "Редагувати файл",
  editing: "Редагується %{file}",
  emptyFolderAdded: "Файл не був доданий — папка пуста",
  encoding: "Обробка...",
  enterCorrectUrl:
    "Неправильный адрес: пожалуйста, убедитесь что вы используете прямую ссылку на файл",
  enterUrlToImport: "Введите адрес, чтобы импортировать файл",
  exceedsSize: "Розмір файлу перевищує обмеження в ",
  failedToFetch:
    "Companion не смог загрузить файл по ссылке, пожалуйста, убедитесь, что адрес верный",
  failedToUpload: "Помилка завантаження %{file}",
  fileSource: "Джерело файли: %{name}",
  filesUploadedOfTotal: {
    0: "%{complete} з %{smart_count} файли завантажено",
    1: "%{complete} з %{smart_count} файлів завантажено",
    2: "%{complete} з %{smart_count} файлів завантажено",
  },
  filter: "Фільтр",
  finishEditingFile: "Закінчити редагування файли",
  folderAdded: {
    0: "Доданий %{smart_count} файл з %{folder}",
    1: "Додано %{smart_count} файли з %{folder}",
    2: "Додано %{smart_count} файлів з %{folder}",
  },
  import: "Імпортувати",
  importFrom: "Імпортувати з %{name}",
  link: "Посилання",
  loading: "Завантаження...",
  logOut: "Вийти",
  myDevice: "Мій пристрій",
  noFilesFound: "Тут немає файлів або папок",
  noInternetConnection: "Нет подключения к интернету",
  pause: "Поставить на паузу",
  pauseUpload: "Поставить загрузку на паузу",
  paused: "На паузе",
  preparingUpload: "Підготовка до завантаження...",
  processingXFiles: {
    0: "Обробляється %{smart_count} файл",
    1: "Обробляється %{smart_count} файли",
    2: "Обробляється %{smart_count} файлів",
  },
  poweredBy: "Працює на",
  removeFile: "Видалити файл",
  resetFilter: "Скинути фільтр",
  resume: "Продовжити",
  resumeUpload: "Продовжити завантаження",
  retry: "Повторити спробу",
  retryUpload: "Повторити спробу завантаження",
  saveChanges: "Зберегти",
  selectX: {
    0: "Вибрати %{smart_count}",
    1: "Вибрати %{smart_count}",
    2: "Вибрати %{smart_count}",
  },
  smile: "Посміхніться!",
  startRecording: "Начать запись видео",
  stopRecording: "Закончить запись видео",
  takePicture: "Сделать фотографию",
  timedOut: "Загрузка остановилась на %{seconds} секунд, отмена",
  upload: "Завантажити",
  uploadComplete: "Завантаження завершене",
  uploadFailed: "Завантаження не вдалося",
  uploadPaused: "Завантаження на паузі",
  uploadXFiles: {
    0: "Завантажити %{smart_count} файл",
    1: "Завантажити %{smart_count} файли",
    2: "Завантажити %{smart_count} файлів",
  },
  uploadXNewFiles: {
    0: "Завантажити +%{smart_count} файл",
    1: "Завантажити +%{smart_count} файли",
    2: "Завантажити +%{smart_count} файлів",
  },
  uploading: "Завантаження",
  uploadingXFiles: {
    0: "Завантажується %{smart_count} файл",
    1: "Завантажується %{smart_count} файли",
    2: "Завантажується %{smart_count} файлів",
  },
  xFilesSelected: {
    0: "%{smart_count} файл вибран",
    1: "%{smart_count} файли вибрано",
    2: "%{smart_count} файлів вибрано",
  },
  xMoreFilesAdded: {
    0: "%{smart_count} додатковий файл доданий",
    1: "%{smart_count} додаткових файли додано",
    2: "%{smart_count} додаткових файлів додано",
  },
  xTimeLeft: "осталось %{time}",
  youCanOnlyUploadFileTypes: "Формат файлу не підтримується",
  youCanOnlyUploadX: {
    0: "Ви можете завантажити тільки %{smart_count} файл",
    1: "Ви можете завантажити тільки %{smart_count} файли",
    2: "Ви можете завантажити тільки %{smart_count} файлів",
  },
  youHaveToAtLeastSelectX: {
    0: "Ви повинні вибрати хочаб %{smart_count} файл",
    1: "Ви повинні вибрати хочаб %{smart_count} файли",
    2: "Ви повинні вибрати хочаб %{smart_count} файлів",
  },
  selectAllFilesFromFolderNamed: "Выбрать все файлы из папки %{name}",
  unselectAllFilesFromFolderNamed:
    "Отменить выбор всех файлов из папки %{name}",
  selectFileNamed: "Вибрати файл %{name}",
  unselectFileNamed: "Відмінити вибір файлу %{name}",
  openFolderNamed: "Відкрити папку %{name}",
};

uk_UA.pluralize = function (n) {
  if (n % 10 === 1 && n % 100 !== 11) {
    return 0;
  }

  if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    return 1;
  }

  return 2;
};

if (typeof window !== "undefined" && typeof window.Uppy !== "undefined") {
  window.Uppy.locales.uk_UA = uk_UA;
}

module.exports = uk_UA;

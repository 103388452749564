export default class DateSubmitter {
  constructor(link_tag) {
    console.log("DateSubmitter::constructor");
    this.init(link_tag);
  }

  init(link_tag) {
    $(document).on("click", link_tag, function (e) {
      e.preventDefault();
      var start_date = $("#start-date");
      var end_date = $("#end-date");
      location.href =
        "?start_date=" + start_date.val() + "&end_date=" + end_date.val();
    });
  }
}

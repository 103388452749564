import consumer from './consumer';
let msgID
consumer.subscriptions.create(
    'MessagesChannel', {
        connected() {
            // Called when the subscription is ready for use on the server
            console.log("Channel connected...");
        },
        received(data) {
            console.log("Data received...")
            const mediaQuery = window.matchMedia("(max-width: 993px)");
            const currentBusinessId = location.pathname.split("/")[2]

            // msgID.push(data.content.msg_id)


            function scrollChat() {
                let showChatItemDesktop = document.querySelector(
                    ".desktop-only .showChat .chat-row__body-field"
                );
                let showChatItemMobile = document.querySelector(
                    ".mobile-only .showChat .chat-row__body-field"
                );
                if (showChatItemDesktop) {
                    showChatItemDesktop.scrollTop = showChatItemDesktop.scrollHeight;
                }
                if (showChatItemMobile) {
                    showChatItemMobile.scrollTop = showChatItemMobile.scrollHeight;
                }
            }

            function sortHeaders(id) {
                const headerId = 'field' + id
                const currentHeader = document.getElementById(headerId)

                if (document.getElementById('chatWrapper') && currentHeader !== null) {
                    const chatHeadersContainer = document.querySelector('#chatWrapper .chat-row')
                    const firstHeader = chatHeadersContainer.firstElementChild
                    firstHeader.before(currentHeader)
                }
            }

            sortHeaders(data.content.chat_id)

            if (data.content && data.business_id === currentBusinessId && data.content.msg_id !== msgID) {

                const chatHeadersList = document.querySelectorAll('.chat-row__header-link')
                let newMsgId = 'field' + data.content.chat_id
                const chatField = document.querySelector('#chatField.chat-row__body-field')

                //new message element:
                const newMsg = document.createElement('div')
                const newMsgHeader = document.createElement('div')
                newMsgHeader.classList.add('message__header')
                let newMsgHeaderName = document.createElement('span')
                newMsgHeaderName.classList.add('message__header-name')
                let newMsgHeaderDate = document.createElement('span')
                newMsgHeaderDate.classList.add('message__header-date')
                let newMsgText = document.createElement('div')
                const wrap = document.createElement('div')
                let lastMsg = data.content.msg_data
                let username
                let chatIdList = []

                chatHeadersList.forEach(element => {
                    chatIdList.push(element.id)

                    if (element.id === newMsgId) {
                        element.querySelector('.preview.message').textContent = data.content.msg_data
                        username = element.querySelector('.username').textContent

                        if (data.content.sender_id === undefined &&
                            data.content.chat_id !== +chatField.id) {
                            element.querySelector('.chat-row__header-info').classList.add('new-message')
                        }
                    }
                })

                // ---------------- NEW CHAT
                let id = 'field' + data.content.chat_id

                if (!chatIdList.includes(id)) {

                    const newHeader = document.createElement('div')
                    newHeader.classList.add('chat-row__header-link')
                    const chatRowHeader = document.createElement('div')
                    chatRowHeader.classList.add('chat-row__header')
                        // chatRowHeader.classList.add('chat-row__header-link')
                    const chatRowHeaderAvatar = document.createElement('div')
                    chatRowHeaderAvatar.classList.add('chat-row__header-avatar')
                    const chatRowHeaderInfo = document.createElement('div')
                    chatRowHeaderInfo.classList.add('chat-row__header-info', 'new-message')
                    const newUsername = document.createElement('div')
                    newUsername.classList.add('username')
                    if (data.new_user !== null) {
                        newUsername.textContent = data.new_user
                    } else {
                        newUsername.textContent = 'Анонімний'
                    }
                    const newContent = document.createElement('div')
                    newContent.classList.add('preview', 'message')
                    newContent.textContent = data.content.msg_data

                    newHeader.id = 'field' + data.content.chat_id
                    newHeader.classList.add('chat-row__header', 'new-header')
                    newHeader.href = window.location.pathname + '/' + data.content.chat_id
                    newHeader.dataset.remote = true
                    chatRowHeaderInfo.append(newUsername, newContent)
                    chatRowHeader.append(chatRowHeaderAvatar, chatRowHeaderInfo)
                    newHeader.append(chatRowHeader)

                    const headersList = document.querySelector('#chatWrapper .chat-row')
                    if (headersList !== null) {
                        headersList.insertBefore(newHeader, headersList.firstChild)
                    }

                    // ------------------------------------------------
                } else {
                    if (data.content.sender_id &&
                        +chatField.dataset.chatId === data.content.chat_id &&
                        data.content.msg_id !== msgID) {

                        console.log('message ID  ' + msgID)
                        console.log(data.content.msg_id);

                        newMsg.classList.add('company-message')
                        newMsgText.classList.add('message__body', 'message__body-company')
                        newMsgHeaderName.textContent = 'Ви'
                        newMsgHeader.append(newMsgHeaderName, newMsgHeaderDate)
                        wrap.append(newMsgHeader, newMsgText)
                        newMsg.append(wrap)
                        chatField.appendChild(newMsg)

                        if (mediaQuery.matches &&
                            document.querySelector('#mobile-only .chat-row__body-field')) {
                            document.querySelector('#mobile-only .chat-row__body-field').appendChild(newMsg)
                            document.querySelector('#mobile-only #messageForm input[type="text"]').value = ''
                            document.querySelector('#mobile-only #messageForm input[type="submit"]').disabled = true
                        } else if (!document.hidden) {
                            chatField.appendChild(newMsg)
                        }

                    } else if (chatField && +chatField.dataset.chatId === data.content.chat_id) {
                        newMsg.classList.add('user-message')
                        newMsgText.classList.add('message__body', 'message__body-user')
                        newMsgHeaderName.textContent = username
                        newMsgHeader.append(newMsgHeaderName, newMsgHeaderDate)
                        newMsg.append(newMsgHeader, newMsgText)
                        chatField.appendChild(newMsg)
                        if (document.querySelector('.active-header').querySelector('.chat-row__header-info').classList.contains('new-message')) {
                            document.querySelector('.active-header').querySelector('.chat-row__header-info').classList.remove('new-message')
                        }
                    }

                    newMsgHeaderDate.textContent = data.content.timestamp
                    newMsgText.textContent = lastMsg
                    let date = new Date(data.content.timestamp)
                    let hours = date.getHours()
                    let minutes = date.getMinutes()
                    hours = hours <= 9 ? '0' + hours : hours
                    minutes = minutes <= 9 ? '0' + minutes : minutes
                    let time = hours + ':' + minutes
                    newMsgHeaderDate.textContent = 'сьогодні, ' + time
                }
            }
            scrollChat()
            if (document.getElementById('chatField')) {
                document.getElementById('chatField').scrollTop = document.getElementById('chatField').scrollHeight;
            }
            if (document.querySelector('#messageForm')) {
                document.querySelector('#messageForm input[type="text"]').value = ''
                document.querySelector('#messageForm input[type="submit"]').disabled = true
            }

            msgID = data.content.msg_id
        },
    },
);

document.addEventListener('DOMContentLoaded', () => {
    const messageForm = document.getElementById('messageForm')
    const messageFormMobile = document.querySelector('#mobile-only #messageForm')
    const mediaQuery = window.matchMedia("(max-width: 993px)");

    if (messageForm) {
        const messageInput = messageForm.querySelector('input[type="text"]')
        const messageSubmit = messageForm.querySelector('input[class="send-btn"]')
        messageSubmit.disabled = true

        messageInput.addEventListener('input', () => {
            if (messageInput.value.length !== 0) {
                messageSubmit.disabled = false
            } else {
                messageSubmit.disabled = true
            }
        })
    }

    if (mediaQuery.matches && messageFormMobile) {
        messageFormMobile.querySelector('input[class="send-btn"]').disabled = true
        messageFormMobile.addEventListener('input', () => {
            if (messageFormMobile.querySelector('input[type="text"]').value.length !== 0) {
                messageFormMobile.querySelector('input[class="send-btn"]').disabled = false
            } else {
                messageFormMobile.querySelector('input[class="send-btn"]').disabled = true
            }
        })
    }

    if (mediaQuery.matches) {
        const mobileMsgHeader = document.querySelectorAll('#user_messages-show #mobile-only .chat-row__header:not(.hide)')
        if (mobileMsgHeader.length > 0) {
            mobileMsgHeader.forEach(item => {
                item.addEventListener('click', () => {
                    location.pathname = location.pathname.substring(0, location.pathname.lastIndexOf('/'))
                })
            })
        }
    }
})
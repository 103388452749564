export default class Payments {
  constructor() {
    console.log("Payments::constructor");
    this.init();
  }
  init() {
    $(document).on("click", function (e) {});

    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    document.addEventListener("DOMContentLoaded", function () {
      let submitBtn = document.querySelector(
        '.payments-item__footer-btn sdk-button'
      );

      if (submitBtn) {
        submitBtn._shadowRoot.children[1].children[0].style.display = 'none'
        submitBtn._shadowRoot.children[1].children[1].textContent = 'Оплатити'
        submitBtn._shadowRoot.children[1].children[1].style.textTransform = 'none'
        submitBtn._shadowRoot.children[1].children[1].style.margin = '0px'
        submitBtn._shadowRoot.children[1].disabled = true;
        submitBtn._shadowRoot.firstElementChild.textContent = ':host {display: block;}' +
          '.sdk {\n' +
          '            width: 113px;\n' +
          '            cursor: pointer;\n' +
          '            height: 36px;\n' +
          '            display: flex;\n' +
          '            align-items: center;\n' +
          '            justify-content: center;\n' +
          '            font-weight: 500;\n' +
          '            font-size: 16px;\n' +
          '            color: #fff;\n' +
          '            background: #1ebcd7;\n' +
          '            border-radius: 5px;\n' +
          '            border: none;\n' +
          '            outline: none;\n' +
          '            outline-width: 0;\n' +
          '            -webkit-appearance: none;\n' +
          '\n' +
          '            &:hover {\n' +
          '              background-color: #fff;\n' +
          '              color: #1ebcd7;\n' +
          '              border: 1px solid #1ebcd7;\n' +
          '              transition: all 0.3s ease-in;\n' +
          '            }\n' +
          '\n' +
          '            @media (max-width: 620px) {\n' +
          '              width: 100%;\n' +
          '            }\n' +
          '          }' +
          '.sdk[disabled] {\n' +
          '            background-color: #d3d3d3;\n' +
          '            font-weight: 400;\n' +
          '            cursor: default;\n' +
          '            color: #888;\n' +
          '\n' +
          '            &:hover {\n' +
          '              background-color: #d3d3d3;\n' +
          '              font-weight: 400;\n' +
          '              color: #888;\n' +
          '              outline: none;\n' +
          '              border-color: #d3d3d3;\n' +
          '            }\n' +
          '          }'

      }

      let paymentsCheckbox = document.getElementById("paymentsCheckbox");
      if (paymentsCheckbox) {
        paymentsCheckbox.checked = false;
        paymentsCheckbox.addEventListener("change", function () {
          if (paymentsCheckbox.checked === false) {
            submitBtn._shadowRoot.children[1].disabled = true;
          } else {
            submitBtn._shadowRoot.children[1].disabled = false;
          }
        });
      }

      const businessList = document.getElementById('businessList')
      if (businessList.children.length === 0) {
        businessList.style.display = 'none'
      }
    });
  }
}

$("#send_data").on("click", () => {
  function getDateObjectF(dateString) {
    let dateParts = dateString.split(".");
    let dateYear = dateParts[2];
    let dateMonth = dateParts[1];
    let dateDay = dateParts[0];
    return new Date(dateYear, dateMonth - 1, dateDay);
  }

  let startDate = document.getElementById("start-date").value;
  let endDate = document.getElementById("end-date").value;
  let startDateInit = getDateObjectF(startDate);
  let endDateInit = getDateObjectF(endDate);
  if (
    startDate.length !== 0 &&
    endDate.length !== 0 &&
    startDateInit <= endDateInit
  ) {
    sendFun();
  }
});

$(".select-date form").on("reset", () => {
  setTimeout(sendFun, 100);
});

function sendFun() {
  let start_date = document.getElementById("start-date").value;
  let end_date = document.getElementById("end-date").value;
  let all_transactions = document.getElementById("all_transactions").value;
  $.ajax({
    dataType: "json",
    url: "/payments",
    type: "POST",
    data: {
      start_date,
      end_date,
      all_transactions,
    },
    success: function (data) {
      let transactionsContainer = document.getElementById("transactions");
      let invoiceArr = [];

      if (data.initial_balance !== null) {
        $("#initial_balance").text(`${data.initial_balance} грн`);
        if (data.initial_balance < 0) {
          $("#initial_balance").css("color", "#D71E1E");
        }
        if (data.initial_balance > 0) {
          $("#initial_balance").css("color", "#008000");
        }
        if (data.initial_balance == 0) {
          $("#initial_balance").css("color", "#333");
        }
      } else {
        $("#initial_balance").text("0 грн").css("color", "#333");
      }

      if (data.ending_balance !== null) {
        $("#the_ending_balance").text(`${data.ending_balance} грн`);
        if (data.ending_balance < 0) {
          $("#the_ending_balance").css("color", "#D71E1E");
        }
        if (data.ending_balance > 0) {
          $("#the_ending_balance").css("color", "#008000");
        }
        if (data.ending_balance == 0) {
          $("#the_ending_balance").css("color", "#333");
        }
      } else {
        $("#the_ending_balance").text("0 грн").css("color", "#333");
      }

      if (data.transactions.length !== 0) {
        transactionsContainer.classList.remove(
          "order-list-placeholder",
          "scale-up-ver-top"
        );
      } else {
        transactionsContainer.classList.add(
          "order-list-placeholder",
          "scale-up-ver-top"
        );
      }

      transactionsContainer.innerHTML = "";

      $.each(data.transactions, function (index) {
        const { code, date, type, value } = this;
        const row = document.createElement("div");
        const billNumber = document.createElement("div");
        const billDate = document.createElement("div");
        const billAmount = document.createElement("div");
        const billContainer = document.createElement("div");
        const orderContainer = document.createElement("div");

        billContainer.setAttribute("class", "order-list__item item-bill");
        orderContainer.setAttribute("class", "order-list__item item-order");

        if (this.type === "invoice") {
          transactionsContainer.appendChild(billContainer);
        }
        if (this.type === "payment") {
          transactionsContainer.appendChild(orderContainer);
        }

        row.setAttribute("class", "row");
        billNumber.setAttribute("class", "bill-number col-6");
        billDate.setAttribute("class", "bill-date col-3");
        billAmount.setAttribute("class", "bill-amount col-2");

        billNumber.innerText = `${
          type === "invoice" ? "Рахунок" : "Платіжне доручення"
        } №${code}`;
        billDate.innerText = `від ${date}`;
        billAmount.innerText = `${value} грн`;

        row.appendChild(billNumber);
        row.appendChild(billDate);
        row.appendChild(billAmount);

        if (type === "invoice") {
          invoiceArr.push(this);
          const pdfIcon = document.createElement("div");
          const pdfIconLink = document.createElement("a");
          pdfIcon.setAttribute("class", "pdf-icon col-1");
          pdfIconLink.setAttribute("target", "_blank");
          for (let i = 0; i < invoiceArr.length; i++) {
            pdfIconLink.href = data.pdf_links[i];
          }
          pdfIcon.appendChild(pdfIconLink);
          row.appendChild(pdfIcon);
          billContainer.appendChild(row);
        } else {
          orderContainer.appendChild(row);
        }
      });
    },
  });
}

let dateInputs = document.querySelectorAll(
  "#payments-payment_api_data .modal-content input.datepicker"
);

if (dateInputs) {
  function getDateObject(dateString) {
    let dateParts = dateString.split(".");
    let dateYear = dateParts[2];
    let dateMonth = dateParts[1];
    let dateDay = dateParts[0];
    return new Date(dateYear, dateMonth - 1, dateDay);
  }

  function enableBtn() {
    let startDate = getDateObject(dateInputs[0].value);
    let endDate = getDateObject(dateInputs[1].value);
    if (
      dateInputs[0].value !== "" &&
      dateInputs[1].value !== "" &&
      startDate <= endDate
    ) {
      document.getElementById("send_data").classList.remove("non-active");
    } else {
      document.getElementById("send_data").classList.add("non-active");
    }
  }

  for (let i = 0; i < dateInputs.length; i++) {
    dateInputs[i].addEventListener("blur", function () {
      setTimeout(enableBtn, 500);
    });
  }

  if (
    document.querySelector("#payments-payment_api_data .modal-content form")
  ) {
    document
      .querySelector("#payments-payment_api_data .modal-content form")
      .addEventListener("reset", function () {
        document.getElementById("send_data").classList.add("non-active");
      });
  }
}

document.addEventListener("DOMContentLoaded", function () {
  let footerBalanceContainer = document.getElementById("footerBalance");
  let balanceValue = document.getElementById("balanceValue");
  let endingBalanceInit = document.getElementById("the_ending_balance");
  let initialBalanceInit = document.getElementById("initial_balance");

  if (endingBalanceInit && parseInt(endingBalanceInit.textContent) < 0) {
    endingBalanceInit.style.color = "#D71E1E";
  } else if (endingBalanceInit && parseInt(endingBalanceInit.textContent) > 0) {
    endingBalanceInit.style.color = "#008000";
  }

  if (initialBalanceInit && parseInt(initialBalanceInit.textContent) < 0) {
    initialBalanceInit.style.color = "#D71E1E";
  } else if (
    initialBalanceInit &&
    parseInt(initialBalanceInit.textContent) > 0
  ) {
    initialBalanceInit.style.color = "#008000";
  }

  if (balanceValue && parseInt(balanceValue.textContent) < 0) {
    footerBalanceContainer.classList.add("balance-negative");
  } else if (balanceValue && parseInt(balanceValue.textContent) > 0) {
    footerBalanceContainer.classList.add("balance-positive");
  }
});
